import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createCampaign: (ctx, data) => axiosIns.post('/api.session', data),
    fetchCampaign: (ctx, q) => axiosIns.get(`/api.session/${q || ''}`),
    fetchCampaigns: (ctx, q) => axiosIns.get(`/api.session/${q || ''}`),
    fetchCampaignsConfirm: (ctx, q) => axiosIns.get(`/api.session/confirm/${q || ''}`),
    updateCampaign: (ctx, data) => axiosIns.put(`/api.session/confirm/${data.id}`, data),
    fetchCampaignsReport: (ctx, id) => axiosIns.get(`/api.session/report/${id}`),
    fetchCampaignsReportToday: (ctx, id) => axiosIns.get(`/api.session/report/${id}/today`),
    checkProductUnique: (ctx, data) => axiosIns.post('/api.product/check-address', data),
    createProduct: (ctx, data) => axiosIns.post('/api.product', data),
    fetchProduct: (ctx, data) => axiosIns.get(`/api.product/${data.id}`),
    fetchProducts: (ctx, q) => axiosIns.get(`/api.product/${q || ''}`),
    updateProduct: (ctx, data) => axiosIns.put(`/api.product/${data.id}`, data),
    closeDealProduct: (ctx, data) => axiosIns.post(`/api.product/${data.id}/close-deal`, data),
    openDealProduct: (ctx, id) => axiosIns.delete(`/api.product/${id}/close-deal`),
    refreshProduct: (ctx, id) => axiosIns.get(`/api.product/refresh/${id}`),
    fetchExpireProducts: (ctx, q) => axiosIns.get(`/api.product/expire-contract${q || ''}`),
    deleteProduct: (ctx, id) => axiosIns.delete(`/api.product/${id}`),
    fetchSuggestedListCustomer: (ctx, data) => axiosIns.get(`/api.client/${data.id}/fit${data.q || ''}`),
    exportExcel: (ctx, q) => axiosIns.post(`/api.product/export${q || ''}`),
  },
}
